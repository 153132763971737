var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "550px" } }, [
    _c(
      "div",
      {
        staticClass:
          "flex justify-between items-center flex-column relative w-100",
      },
      [
        _vm.adsData.length === 0
          ? _c(
              "div",
              { staticClass: "flex flex-column items-center ml5 mt5" },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/monetize/file.png"),
                    alt: "",
                    width: "200",
                    height: "200",
                  },
                }),
                _c("BaseButtonRed", {
                  staticClass: "ph4 mt3 mr1 h2 mt5",
                  attrs: { text: "Create Ad", onClick: _vm.handleCreateModal },
                }),
              ],
              1
            )
          : _c(
              "div",
              {
                staticClass:
                  "flex justify-between items-center flex-column relative w-100",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex justify-between items-center w-100 mt3",
                  },
                  [
                    _vm._m(0),
                    _c("div", { staticClass: "flex items-center" }, [
                      _c(
                        "i",
                        {
                          staticClass: "f3 material-icons white mr4 pointer",
                          on: { click: _vm.fetchAds },
                        },
                        [_vm._v("sync")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ph3 pv2 ba b--adori-gray br2 light-gray bg-adori-red pointer",
                          on: { click: _vm.handleCreateModal },
                        },
                        [_vm._v("\n            Create Ad\n          ")]
                      ),
                    ]),
                  ]
                ),
                _vm.loadingAds
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "tc pv3 f5 silver flex w-100 justify-center items-center",
                      },
                      [
                        _c("div", { staticClass: "mr3" }, [
                          _vm._v("Loading..."),
                        ]),
                        _c("div", { staticClass: "loader loader-inline" }),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "w-100 mt3 scroll" },
                      [
                        _vm._l(_vm.adsData, function (ad, index) {
                          return _c("ThAdList", {
                            key: index,
                            attrs: { ad: ad, selectable: "" },
                          })
                        }),
                        _vm.hasItemsLeft
                          ? _c(
                              "div",
                              {
                                staticClass: "tc pv3 ph4 bt b--adori-gray bw1",
                              },
                              [
                                _c("BaseButtonLoadMore", {
                                  attrs: {
                                    rootBottomMargin: "800",
                                    text: "Load more",
                                    onClick: _vm.hasItemsLeftLoad,
                                    onVisible: _vm.hasItemsLeftLoad,
                                    loading: _vm.isLoading(),
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                !_vm.loadingAds
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex justify-end items-center mt4 w-100 white",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mb2 flex justify-between" },
                          [
                            _c("BaseButtonBorder", {
                              staticClass: "bw0 bg-adori-light-gray fr",
                              attrs: {
                                text: "Back",
                                onClick: function () {
                                  _vm.$emit("backToDetails")
                                },
                              },
                            }),
                            _vm.selectedAudioUids.length > 0 &&
                            _vm.kind !== "PROGRAMMATIC"
                              ? _c("BaseButtonRed", {
                                  staticClass: "fl ml2",
                                  attrs: {
                                    text: "Next",
                                    onClick: _vm.handleNext,
                                  },
                                })
                              : _vm._e(),
                            _vm.isUploadingTag
                              ? _c("div", {
                                  staticClass: "fl loader loader-inline mt2",
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
        _vm.createModalStatus
          ? _c("ModalAudioAd", {
              attrs: { modalCreate: "" },
              on: { close: _vm.closeModal, create: _vm.adCreated },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex flex-column justify-center" }, [
      _c("span", { staticClass: "f4" }, [_vm._v("Ad Bucket")]),
      _c("span", { staticClass: "f6" }, [
        _vm._v("Select Ad from the Ad Bucket"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }