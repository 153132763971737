var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toggle-btn", on: { change: _vm.toggle } }, [
    _c("input", {
      staticClass: "pointer",
      attrs: {
        type: "checkbox",
        name: "toggle",
        id: "toggl",
        disabled: _vm.disabled,
      },
      domProps: { checked: _vm.value },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }