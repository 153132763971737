


































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import moment from 'moment'
import ProgressBar from 'vuejs-progress-bar'
import ModalCreateFlight from '@/components/Campaigns/Modals/ModalCreateFlight.vue'
import ModalConfirm from '@/components/Campaigns/Modals/ModalConfirm.vue'
import ModalTagettedShows from '@/components/Campaigns/Modals/ModalTagettedShows.vue'
import TheToggleSwitch from '@/components/Common/TheToggleSwitch.vue'

import { filter, promoType } from '@/components/Campaigns/configs'

import { options } from '@/components/Campaigns/campaigns'

@Component({
  components: {
    ProgressBar,
    ModalCreateFlight,
    ModalConfirm,
    TheToggleSwitch,
    ModalTagettedShows,
  },
})
export default class TheAdList extends Vue {
  @Getter networkId!: string
  @Getter hasLoadedUser!: any
  @Prop() flight!: any
  @Prop() campaign!: any

  @Action updateFlight!: any
  @Action deleteFlight!: any

  modalStatus = false
  selectedState!: boolean
  modalConfirm = false
  modalTarget = false
  disabled = false
  status = ''
  confirmText = ''

  playPause = false
  audio!: any

  created() {
    this.audio = new Audio(this.flight.provider.audioTrackURL)
  }

  preview() {
    if (this.playPause) {
      this.playPause = false
      this.audio.pause()
    } else {
      this.playPause = true
      this.audio.play()
    }
  }

  get options() {
    return options
  }

  get promoType() {
    return promoType
  }

  get cursor() {
    if (
      [this.filterConfig.COMPLETE, this.filterConfig.ARCHIVED].includes(this.campaign.status) ||
      [this.filterConfig.ARCHIVED].includes(this.flight.status)
    ) {
      return 'not-allowed'
    } else return 'pointer'
  }

  get filterConfig() {
    return filter
  }

  get progress() {
    const max = 100 / this.flight.maxServes

    return {
      textValue: this.flight.servedCount,
      barValue: Math.round(max * this.flight.servedCount),
      maxValue: this.flight.maxServes,
    }
  }

  get flightMenu() {
    const enable = {
      name: 'Enable',
      icon: 'visibility',
      onClick: async () => {
        this.confirmText = 'Are you sure, you want to Enable?'
        this.status = this.filterConfig.ACTIVE
        this.modalConfirm = true
      },
    }

    const disable = {
      name: 'Disable',
      icon: 'visibility_off',
      onClick: () => {
        this.confirmText = 'Are you sure, you want to disable?'
        this.status = this.filterConfig.DISABLED
        this.modalConfirm = true
      },
    }

    const edit = {
      name: 'Edit',
      icon: 'edit',
      onClick: () => {
        this.modalStatus = true
      },
    }

    const archive = {
      name: 'Archive',
      icon: 'archive',
      onClick: () => {
        this.confirmText = 'Are you sure, you want to archive? It cannot be enabled again and flight stops serving'
        this.status = this.filterConfig.ARCHIVED
        this.modalConfirm = true
      },
    }
    const targetShow = {
      name: 'Targeted Shows',
      icon: 'track_changes',
      onClick: () => {
        this.modalTarget = true
      },
    }

    if ([this.filterConfig.COMPLETE, this.filterConfig.ARCHIVED].includes(this.campaign.status)) return []
    else if (this.flight.status === this.filterConfig.DISABLED) return [edit, enable, archive, targetShow]
    else if (this.flight.status === this.filterConfig.INACTIVE) return [edit, archive, targetShow]
    else if (this.flight.status === this.filterConfig.ACTIVE) return [edit, disable, archive, targetShow]
    else if (this.flight.status === this.filterConfig.COMPLETE) return [archive]
    else return []
  }

  async yes() {
    if (this.status === this.filterConfig.ARCHIVED) {
      const payload = {
        networkId: this.networkId,
        campaignId: this.campaign.id,
        flightId: this.flight.id,
      }
      this.modalConfirm = false
      await this.deleteFlight(payload)
      this.$emit('updated')
      return
    }
    const payload = {
      data: {
        status:
          this.flight.status === this.filterConfig.DISABLED ? this.filterConfig.ACTIVE : this.filterConfig.DISABLED,
      },
      networkId: this.networkId,
      campaignId: this.campaign.id,
      flightId: this.flight.id,
    }

    this.modalConfirm = false
    await this.updateFlight(payload)
    this.$emit('updated')
  }

  no() {
    this.modalConfirm = false
  }

  flightDate(startDate: string, endDate: string) {
    let date1 = moment(startDate)
    let date2 = moment(endDate)

    return `${date1.format('DDMMM')}-${date2.format('DDMMM')} ${date2.diff(date1, 'days') + 1} day(s)`
  }

  update() {
    this.$emit('updated')
  }

  closeModal() {
    this.modalStatus = false
    this.modalTarget = false
  }

  beforeDestroy() {
    this.audio.pause()
    this.audio.currentTime = 0
  }
}
