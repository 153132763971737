var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }),
      ]),
      _c(
        "template",
        { slot: "body" },
        [
          _c("div", { staticClass: "flex flex-column w-100 min-w7" }, [
            _c(
              "div",
              { staticClass: "flex justify-center items-center W-100" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "f6 pv1 ph2 silver link pointer mr1 ba br4 b--transparent light-gray",
                        class: { "bg-adori-red": _vm.tabParam === "Details" },
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          on: {
                            click: function ($event) {
                              _vm.tabParam = "Details"
                            },
                          },
                        }),
                        _vm._v("Details\n          "),
                      ]
                    ),
                    !_vm.edit && _vm.campaign.kind !== "PROGRAMMATIC"
                      ? _c(
                          "label",
                          {
                            staticClass:
                              "f6 pv1 ph2 silver link pointer mr1 ba br4 b--transparent light-gray",
                            class: { "bg-adori-red": _vm.tabParam === "Audio" },
                          },
                          [
                            _c("input", {
                              staticClass: "input-reset",
                              attrs: { type: "radio" },
                            }),
                            _vm._v("Audio\n          "),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "label",
                      {
                        staticClass:
                          "f6 pv1 ph2 silver link pointer mr1 ba br4 b--transparent light-gray",
                        class: { "bg-adori-red": _vm.tabParam === "Target" },
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                        }),
                        _vm._v("Target\n          "),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabParam === "Details",
                    expression: "tabParam === 'Details'",
                  },
                ],
                staticClass: "pl3 white pr5",
              },
              [
                _c("FlightDetails", {
                  attrs: { campaign: _vm.campaign, flight: _vm.flight },
                  on: {
                    next: _vm.nextToAudioSelection,
                    close: function () {
                      _vm.$emit("close")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabParam === "Target",
                    expression: "tabParam === 'Target'",
                  },
                ],
                staticClass: "pl3 white pr5 mb5",
              },
              [_c("Target")],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabParam === "Audio",
                    expression: "tabParam === 'Audio'",
                  },
                ],
                staticClass: "pl3 white pr5",
              },
              [
                _c("AudioAdCollection", {
                  attrs: {
                    isUploadingTag: _vm.isUploadingTag,
                    kind: _vm.campaign.kind,
                  },
                  on: {
                    next: _vm.nextToShows,
                    backToDetails: function () {
                      _vm.tabParam = "Details"
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.showModal
            ? _c("ModalSelectShows", {
                attrs: { kind: _vm.campaign.kind },
                on: { close: _vm.closeModal, saveClose: _vm.saveClose },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        !_vm.isEmpty(_vm.targetEpisodeList) && _vm.tabParam === "Target"
          ? _c(
              "div",
              { staticClass: "flex justify-end items-center w-100 white" },
              [
                _c(
                  "div",
                  { staticClass: "mb2 flex justify-between mr5" },
                  [
                    _c("BaseButtonBorder", {
                      staticClass: "bw0 bg-adori-light-gray fr",
                      attrs: {
                        text: "Add More",
                        onClick: function () {
                          _vm.showModal = true
                        },
                      },
                    }),
                    _c("BaseButtonRed", {
                      staticClass: "fl ml2",
                      attrs: {
                        text: _vm.flightButton,
                        onClick: _vm.createAdoriFlight,
                      },
                    }),
                    _vm.isUploadingTag
                      ? _c("div", {
                          staticClass: "fl loader loader-inline mt2",
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }