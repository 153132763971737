



































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

import EpisodeSelection from '@/views/campaign/components/EpisodeSelection.vue'

@Component({
  components: {
    EpisodeSelection,
  },
})
export default class OverviewPodcastCard extends Vue {
  @Prop(String) rssFeedUid!: string
  @Prop(Boolean) isLargeCard!: boolean
  @Prop(Boolean) allowEpisode!: boolean
  @Getter rssFeedsByUid!: any
  @Getter rssFeedItemsByFeedUid!: any
  @Getter selectedRssFeedUid!: any
  @Getter isPodcastSelected!: any
  @Getter targetEpisodeList!: any

  @Action setSelectedRssFeedUid!: any

  isLoading = false
  modalSelectEpisode = false
  get selectionIcon() {
    return this.targetEpisodeList[this.rssFeedUid] ? 'check_circle' : 'radio_button_unchecked'
  }
  get selectionStyle() {
    return this.targetEpisodeList[this.rssFeedUid] ? 'adori-red' : 'o-20'
  }

  get numberOfEpisodes() {
    return this.targetEpisodeList[this.rssFeedUid] ? 'ba b--adori-red' : ''
  }

  async handleEpisodeSelect() {
    if (this.allowEpisode) {
      this.modalSelectEpisode = true
    }
  }
  closeModal() {
    if (this.allowEpisode) {
      this.modalSelectEpisode = false
    }
  }
}
