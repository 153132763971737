var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: true },
      on: {
        closeModal: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "f3 light-gray fw5 di" }),
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "w-100 justify-center f5 white min-w7 min-h7" },
          [
            _c("div", { staticClass: "w-100" }, [
              _c("div", { staticClass: "flex items-end mr4 mb3" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "fw3 f4 dib link glow pt3 pb1 pointer no-outline w-40 mr4 bold",
                    class:
                      _vm.selectedType === "SET_DATE"
                        ? "red bb b--red bw1"
                        : "white",
                    on: {
                      click: function ($event) {
                        _vm.selectedType = "SET_DATE"
                      },
                    },
                  },
                  [_vm._v("\n            Set Dates\n          ")]
                ),
              ]),
              _vm.selectedType === "SET_DATE"
                ? _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "w-50" }, [
                      _c("span", { staticClass: "f6" }, [
                        _vm._v("Set Start Date"),
                      ]),
                      _c("div", { staticClass: "flex mt2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-center items-center ba ph2 br2 pv2 mr2 w-40",
                            class: {
                              "bg-adori-red b--adori-red":
                                _vm.startDateStatus === "BEGINNING",
                              "b--adori-light-gray":
                                _vm.startDateStatus === "DATE",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.startDateChange("BEGINNING")
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons f5 white link pointer mr1",
                                class: {
                                  green: _vm.startDateStatus === "BEGINNING",
                                  "adori-red": _vm.startDateStatus === "DATE",
                                },
                              },
                              [_vm._v("fiber_manual_record")]
                            ),
                            _vm._v(
                              "\n                From Beginning\n              "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-center items-center ba ph2 br2 pv2 w-40",
                            class: {
                              "bg-adori-red b--adori-red":
                                _vm.startDateStatus === "DATE",
                              "b--adori-light-gray":
                                _vm.startDateStatus === "BEGINNING",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.startDateChange("DATE")
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons f5 white link pointer mr1",
                                class: {
                                  green: _vm.startDateStatus === "DATE",
                                  "adori-red":
                                    _vm.startDateStatus === "BEGINNING",
                                },
                              },
                              [_vm._v("fiber_manual_record")]
                            ),
                            _vm._v(
                              "\n                Start Date\n              "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex w-100 mt2" },
                        [
                          _c("div", { staticClass: "w-40 mr2 pa3" }),
                          _c("date-picker", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.startDateStatus === "DATE",
                                expression: "startDateStatus === 'DATE'",
                              },
                            ],
                            ref: "startDate",
                            staticClass: "adori-date-picker w-40",
                            attrs: {
                              lang: "en",
                              type: "date",
                              format: "YYYY-MM-DD",
                              "not-before": _vm.notBeforeEndtDate,
                              "not-after": _vm.notAfterStartDate,
                              placeholder: "Enter Start Date",
                            },
                            on: { input: _vm.handleStartDate },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "w-50" }, [
                      _c("span", { staticClass: "f6" }, [
                        _vm._v("Set End Date"),
                      ]),
                      _c("div", { staticClass: "flex mt2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-center items-center ba ph2 br2 pv2 mr2 w-40",
                            class: {
                              "bg-adori-red b--adori-red":
                                _vm.endDateStatus === "FOREVER",
                              "b--adori-light-gray":
                                _vm.endDateStatus === "DATE",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.endDateChange("FOREVER")
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons f5 white link pointer mr1",
                                class: {
                                  green: _vm.endDateStatus === "FOREVER",
                                  "adori-red": _vm.endDateStatus === "DATE",
                                },
                              },
                              [_vm._v("fiber_manual_record")]
                            ),
                            _vm._v("\n                Forever\n              "),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex justify-center items-center ba ph2 br2 pv2 w-40",
                            class: {
                              "bg-adori-red b--adori-red":
                                _vm.endDateStatus === "DATE",
                              "b--adori-light-gray":
                                _vm.endDateStatus === "FOREVER",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.endDateChange("DATE")
                              },
                            },
                          },
                          [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons f5 white link pointer mr1",
                                class: {
                                  green: _vm.endDateStatus === "DATE",
                                  "adori-red": _vm.endDateStatus === "FOREVER",
                                },
                              },
                              [_vm._v("fiber_manual_record")]
                            ),
                            _vm._v(
                              "\n                End Date\n              "
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex w-100 mt2" },
                        [
                          _c("div", { staticClass: "w-40 mr2 pa3" }),
                          _c("date-picker", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.endDateStatus === "DATE",
                                expression: "endDateStatus === 'DATE'",
                              },
                            ],
                            ref: "endDate",
                            staticClass: "adori-date-picker w-40",
                            attrs: {
                              lang: "en",
                              type: "date",
                              format: "YYYY-MM-DD",
                              "not-before": _vm.notBeforeStartDate,
                              "not-after": _vm.notAfterEndDate,
                              placeholder: "Enter Start Date",
                            },
                            on: { input: _vm.handleEndDate },
                            model: {
                              value: _vm.endDate,
                              callback: function ($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            !_vm.loading && _vm.episodeList.length > 0
              ? _c("div", { staticClass: "mt3" }, [
                  _vm.startDateStatus === "BEGINNING" ||
                  _vm.endDateStatus === "FOREVER"
                    ? _c("div", [
                        _vm._v(
                          "\n          All filtered episodes below will be targeted\n        "
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n          All the episodes published between\n          "
                        ),
                        _c("span", { staticClass: "adori-red" }, [
                          _vm._v(_vm._s(_vm.formatter(this.startDate))),
                        ]),
                        _vm._v(" to\n          "),
                        _c("span", { staticClass: "adori-red" }, [
                          _vm._v(_vm._s(_vm.formatter(this.endDate))),
                        ]),
                        _vm._v(" will be targeted\n        "),
                      ]),
                ])
              : _vm._e(),
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass: "flex justify-center min-w7 overflow-x-hidden",
                  },
                  [
                    _c("div", {
                      staticClass: "loader loader-inline overflow-x-hidden",
                    }),
                  ]
                )
              : _c("div", { staticClass: "w-100" }, [
                  _vm.episodeList.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mt1 custom-height scrollbar" },
                        [
                          _vm._l(_vm.episodeList, function (episode) {
                            return _c("TargetEpisodeList", {
                              key: episode.uid,
                              attrs: { episode: episode },
                            })
                          }),
                          _vm.hasItemsLeft
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "tc pv3 ph4 bt b--adori-gray bw1",
                                },
                                [
                                  _c("BaseButtonLoadMore", {
                                    attrs: {
                                      rootBottomMargin: "800",
                                      text: "Load more",
                                      onClick: _vm.hasItemsLeftLoad,
                                      onVisible: _vm.hasItemsLeftLoad,
                                      loading: _vm.isLoading(),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "w-100 flex flex-column items-center mt2",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/campaign/targetEpisode.svg"),
                              alt: "",
                              height: "200",
                              width: "200",
                            },
                          }),
                          _c("p", [
                            _vm._v(
                              "No episodes have been published between the selected date range"
                            ),
                          ]),
                        ]
                      ),
                ]),
          ]
        ),
      ]),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "flex justify-end items-center w-100 white" },
          [
            _c(
              "div",
              { staticClass: "mb2 flex justify-between" },
              [
                _c("BaseButtonBorder", {
                  staticClass: "bw0 bg-adori-light-gray fr",
                  attrs: {
                    text: "close",
                    onClick: function () {
                      _vm.$emit("close")
                    },
                  },
                }),
                _vm.episodeList.length > 0
                  ? _c("BaseButtonRed", {
                      staticClass: "fl ml2",
                      attrs: { text: "Done", onClick: _vm.handleSelect },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }