








































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import TheMainPage from '@/components/Common/TheMainPage.vue'
import TheHeader from '@/components/Common/TheHeader.vue'
import TheSideNavigation from '@/components/Common/TheSideNavigation.vue'
import TheTitle from '@/components/Common/TheTitle.vue'
import TheFlightList from '@/components/Common/TheFlightList.vue'
import ModalCreateFlight from '@/components/Campaigns/Modals/ModalCreateFlight.vue'

import icons_campaign_icon_active from '@/assets/icons/campaign_icon_active.svg'
import campaign_ACTIVE from '@/assets/campaign/ACTIVE.svg'
import campaign_ALL from '@/assets/campaign/ALL.svg'
import campaign_ARCHIVED from '@/assets/campaign/ARCHIVED.svg'
import campaign_COMPLETE from '@/assets/campaign/COMPLETE.svg'
import campaign_DISABLED from '@/assets/campaign/DISABLED.svg'
import campaign_INACTIVE from '@/assets/campaign/INACTIVE.svg'

import { filter, promoType } from '@/components/Campaigns/configs'
import { campaignQueryKeys, useGeFlights, useGetOneCampaign } from '@/hooks/campaign'
import { reactive, SetupContext } from '@vue/composition-api'
import { FlightFetchParams } from '@/Interfaces/StoreInterFaces/campaignInterfaces/Flight'
import get from 'lodash.get'
import { QueryClient, useQueryClient } from 'vue-query'
@Component({
  components: {
    TheMainPage,
    TheHeader,
    TheSideNavigation,
    TheTitle,
    TheFlightList,
    ModalCreateFlight,
  },
  setup(_, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const campaignId = root.$route.params.id
    const flightParams = reactive({
      limit: 10,
      offset: 0,
      status: undefined,
    })
    const { data: flights, isFetching: isFlightFetching } = useGeFlights(campaignId, flightParams)
    const { data: campaign, isFetching: isCampaignFetching } = useGetOneCampaign(campaignId)
    return {
      queryClient,
      flightParams,
      campaignId,
      flights,
      isFlightFetching,
      campaign,
      isCampaignFetching,
    }
  },
})
export default class ViewCampaignFlight extends Vue {
  @Action showAudioAddPlayer!: any
  currentAudio!: any
  currentUrl!: string

  flightModalStatus = false

  flightSearchValue = ''
  currentTab: 'ALL' | 'ACTIVE' | 'ARCHIVED' | 'COMPLETE' | 'DISABLED' | 'INACTIVE' = 'ALL'
  pageNo = 1
  increment = 1
  timer: number = 0

  queryClient!: QueryClient
  flightParams!: FlightFetchParams
  campaignId!: string
  flights!: any
  isFlightFetching!: boolean
  campaign!: any
  isCampaignFetching!: boolean

  get promoType() {
    return promoType
  }

  get flightsData() {
    return get(this.flights, 'data', [])
  }

  get flightsCount() {
    return get(this.flights, 'count', 0)
  }

  get pageCount() {
    return Math.ceil(get(this.flights, 'count', 0) / 10)
  }

  get filterConfig() {
    return filter
  }

  get campaign_icon_active() {
    return icons_campaign_icon_active
  }

  get placHolderSrc() {
    const tabSrc = {
      ACTIVE: campaign_ACTIVE,
      ALL: campaign_ALL,
      ARCHIVED: campaign_ARCHIVED,
      COMPLETE: campaign_COMPLETE,
      DISABLED: campaign_DISABLED,
      INACTIVE: campaign_INACTIVE,
    }
    return tabSrc[this.currentTab]
  }

  @Watch('pageNo')
  watchPageNo() {
    this.flightParams.offset = (this.pageNo - 1) * this.increment
  }

  next() {
    if (this.pageNo + 1 <= this.pageCount) {
      this.pageNo += 1
    }
  }

  prev() {
    if (this.pageNo > 1) {
      this.pageNo -= 1
    }
  }

  OnPageNumberChanged(page: number) {
    if (this.timer) {
      window.clearTimeout(this.timer)
    }
    this.timer = window.setTimeout(async () => {
      if (page <= this.pageCount && page > 0) {
        this.pageNo = page
      }
    }, 600)
  }

  checkIfAll(value: string | undefined) {
    if (value === 'ALL') {
      return undefined
    }
    return value
  }

  handleTabChange(tab: any) {
    this.currentTab = tab
    this.flightParams.status = this.checkIfAll(tab)
  }

  async update() {
    this.queryClient.invalidateQueries(campaignQueryKeys.FLIGHT)
    this.queryClient.invalidateQueries(campaignQueryKeys.CAMPAIGN)
    this.queryClient.invalidateQueries([campaignQueryKeys.ONE_CAMPAIGN, this.campaignId])
  }

  createFlight() {
    this.flightModalStatus = true
  }

  closeModal() {
    this.flightModalStatus = false
  }
}
