



































































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import LazyLoader from '@/mixins/LazyLoader'

// components
import ThAdList from '@/components/Common/ThAdList.vue'
import ModalAudioAd from '@/components/Campaigns/Modals/ModalAudioAd.vue'

// constants
import { TagProvider } from '@/components/Tags/tags'

@Component({
  components: {
    ThAdList,
    ModalAudioAd,
  },
})
export default class AudioAdCollection extends Mixins(LazyLoader) {
  @Prop(String) kind!: string
  @Prop(Boolean) isUploadingTag!: boolean
  @Getter audioAds!: any
  @Getter selectedAudioUids!: any
  @Getter current!: any

  @Action getAudioAds!: any

  loadingAds = false

  @Watch('current')
  async reload() {
    if (this.current === null) {
      this.fetchAds()
    }
  }

  get adsData() {
    return this.getItemsLazy(() => this.audioAds.data)
  }

  async created() {
    await this.fetchAds()
  }

  async fetchAds() {
    this.hasItemsLeft = true
    this.loadingAds = true
    this.resetIterations()
    await this.getAudioAds({
      limit: 20,
      offset: 0,
      category: 'AD',
    })
    this.loadingAds = false
  }

  async hasItemsLeftLoad() {
    if (!this.isLoading()) {
      let payload: any = {
        limit: 20,
        offset: (this.audioAds && this.audioAds.data.length) || 0,
        category: 'AD',
      }
      await this.handleLoadMore(() => this.audioAds, this.getAudioAds, payload)
    }
  }

  createModalStatus: boolean = false

  async closeModal() {
    this.createModalStatus = false
  }

  adCreated() {
    this.createModalStatus = false
    this.fetchAds()
  }

  handleCreateModal() {
    this.createModalStatus = true
  }

  handleNext() {
    const payload: any = {
      kind: TagProvider.PROMO,
      audioTrackUid: this.selectedAudioUids[0],
    }
    this.$emit('next', payload)
  }

  beforeDestroy() {
    this.$store.dispatch('unselectAllAudios')
  }
}
