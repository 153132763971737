

























































































































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import OveriviewSimplePodcast from '@/components/Overview/OveriviewSimplePodcast.vue'
import debounce from 'lodash.debounce'
import LazyLoader from '@/mixins/LazyLoader'
import isEmpty from 'lodash.isempty'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'

@Component({
  components: {
    OveriviewSimplePodcast,
  },
})
export default class ModalSelectShows extends Mixins(LazyLoader) {
  @Getter rssFeedUids!: any
  @Getter rssFeedsCount!: any
  @Getter rssFeedsLazyLoader!: any
  @Getter searchedRssFeedsLazyLoader!: any
  @Getter rssFeed!: any
  @Getter hasLoadedUser!: any
  @Getter networkId!: any
  @Getter rssFeeds!: any
  @Getter targetEpisodeList!: any
  @Getter currentNetwork!: any

  @Action getRssFeeds!: any
  @Action searchRssFeedUids!: any
  @Action setRssFeedsSortOrder!: any

  sortOrder = 'Latest Episode'
  sortList = ['Latest Episode', 'A-Z', 'Z-A']

  podcastSearchValue: string = ''
  podcastSortOrder: string = ''
  isSearching: boolean = false
  searchNumber: number = 0
  isLoadingRssFeeds: boolean = false
  modalSelectEpisode: boolean = false

  selection = 'select'

  get filteredList() {
    const list = [...this.rssFeeds]
    let filteredList = []
    switch (this.podcastSortOrder) {
      case 'Latest Episode':
        filteredList = list.sort((a: any, b: any) => a.createdOn - b.createdOn).reverse()
        break
      case 'A-Z':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() >= b.name.toLowerCase() ? 1 : -1))
        break
      case 'Z-A':
        filteredList = list.sort((a: any, b: any) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1))
        break
      default:
        filteredList = list
    }
    if (this.podcastSearchValue) {
      return filteredList.filter((feed: any) => feed.name.toLowerCase().includes(this.podcastSearchValue.toLowerCase()))
    }
    return filteredList
  }

  get networkFeedList() {
    return get(this.currentNetwork, 'feeds', false)
      ? this.currentNetwork?.feeds?.reduce((acc: any, feed: any) => {
          if (feed.role.name === 'Show Admin') {
            acc.push(feed.feed.uid)
          }
          return acc
        }, [])
      : []
  }

  get filteredRssFeeds() {
    return this.getItemsLazy(() =>
      this.filteredList.filter((feed: any) =>
        this.networkFeedList.length ? this.networkFeedList.includes(feed.uid) : !!feed.uid
      )
    )
    // return this.getItemsLazy(() => this.filteredList)
  }

  isEmpty(obj: any) {
    return isEmpty(obj)
  }

  async hasItemsLeftLoad() {
    if (this.podcastSearchValue) {
      await this.handleLoadMore(() => this.searchedRssFeedsLazyLoader, this.getRssFeeds, {
        limit: 10,
        clear: false,
        query: this.podcastSearchValue,
      })
    } else {
      await this.handleLoadMore(() => this.rssFeedsLazyLoader, this.getRssFeeds, {
        limit: 10,
        clear: false,
        query: this.podcastSearchValue,
      })
    }
  }

  changeSort() {
    this.podcastSortOrder = this.sortOrder
    // this.setRssFeedsSortOrder(sortOrder)
    this.resetIterations()
    this.setItemsLeft(true)
  }

  handlePodcastData(data: any) {
    this.$emit('podcastData', data)
  }
  beforeDestroy() {
    this.setRssFeedsSortOrder('')
  }

  setLazyLoaderDefaults() {
    this.increment = 10
  }
  async mounted() {
    window.scrollTo(0, 0)
    if (this.$refs._searchPodcasts !== undefined) {
      // @ts-ignore
      this.$refs._searchPodcasts.focus()
    }
    if (this.hasLoadedUser && (this.rssFeedUids.length === 0 || this.rssFeedUids.length < this.rssFeedsCount - 1)) {
      this.isLoadingRssFeeds = true
      await this.getRssFeeds({ paginate: false })
      this.isLoadingRssFeeds = false
    }
  }

  async onPodcastSearchValueChanged() {
    this.isSearching = true
    await this.searchRssFeedUids(this.podcastSearchValue)
    this.setItemsLeft(true)
    this.resetIterations()
    this.isSearching = false
  }
  debouncedSearch = debounce(() => {
    this.onPodcastSearchValueChanged()
  }, 300)

  clearSearch() {
    this.podcastSearchValue = ''
    this.onPodcastSearchValueChanged()
  }

  select() {
    this.selection = 'select'
  }

  unselect() {
    this.$store.commit('unselectAllEpisodes')
  }
}
