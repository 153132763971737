






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TheToggleSwitch extends Vue {
  @Prop(Boolean) value!: boolean
  @Prop(Boolean) disabled!: boolean

  toggle() {
    this.$emit('toggle')
  }
}
