var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-wrap justify-between ph2 pv3 link light-gray bg-adori-light-gray bb b--adori-gray mb relative",
        },
        [
          _c("div", { staticClass: "w-20 flex justify-center items-center" }, [
            _vm._v(_vm._s(_vm.flight.name)),
          ]),
          _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  [_vm.flight.startTime, _vm.flight.endTime].includes(null)
                    ? "-"
                    : _vm.flightDate(_vm.flight.startTime, _vm.flight.endTime)
                ) +
                "\n    "
            ),
          ]),
          _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
            _vm._v("\n      " + _vm._s(_vm.flight.position) + "\n    "),
          ]),
          _vm.flight.maxServes === null
            ? _c(
                "div",
                { staticClass: "w-10 flex justify-center items-center" },
                [_vm._v("-")]
              )
            : _c(
                "div",
                { staticClass: "w-10 flex justify-start items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-column" },
                    [
                      _c("ProgressBar", {
                        attrs: {
                          options: _vm.options,
                          value: _vm.progress.barValue,
                          max: 100,
                        },
                      }),
                      _c("span", { staticClass: "f7" }, [
                        _vm._v(
                          _vm._s(_vm.progress.textValue) +
                            "/" +
                            _vm._s(_vm.progress.maxValue)
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
          _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
            _vm._v("\n      " + _vm._s(_vm.flight.positionNumber) + "\n    "),
          ]),
          _vm.campaign.kind !== _vm.promoType.PROGRAMMATIC
            ? _c(
                "div",
                {
                  staticClass: "w-10 flex justify-center items-center pointer",
                  on: { click: _vm.preview },
                },
                [
                  _c("i", { staticClass: "f3 material-icons red inline mr2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.playPause
                          ? "pause_circle_outline"
                          : "play_circle_outline"
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "w-10 flex justify-center items-center" }, [
            _c(
              "button",
              {
                staticClass: "w-100 pv2 bn white br2 f6",
                class: {
                  "bg-adori-red": _vm.flight.status == _vm.filterConfig.ACTIVE,
                  "bg-adori-very-light-gray": [
                    _vm.filterConfig.INACTIVE,
                    _vm.filterConfig.DISABLED,
                    _vm.filterConfig.ARCHIVED,
                  ].includes(_vm.flight.status),
                  "bg-green": _vm.flight.status == _vm.filterConfig.COMPLETE,
                },
              },
              [_vm._v("\n        " + _vm._s(_vm.flight.status) + "\n      ")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-5 flex justify-center items-center" },
            [
              _c("BasePopupMenu", {
                attrs: {
                  icon: "more_vert",
                  cursor: _vm.cursor,
                  items: _vm.flightMenu,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm.modalStatus
        ? _c("ModalCreateFlight", {
            attrs: { edit: "", campaign: _vm.campaign, flight: _vm.flight },
            on: { updated: _vm.update, close: _vm.closeModal },
          })
        : _vm._e(),
      _vm.modalConfirm
        ? _c("ModalConfirm", {
            attrs: { text: _vm.confirmText },
            on: { yes: _vm.yes, no: _vm.no },
          })
        : _vm._e(),
      _vm.modalTarget
        ? _c("ModalTagettedShows", {
            attrs: { campaign: _vm.campaign, flight: _vm.flight },
            on: { close: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }