var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-column justify-between br2 light-gray podcast-card-small",
    },
    [
      _c("div", {}, [
        _c(
          "div",
          {
            staticClass: "title flex justify-between items-top",
            on: { click: _vm.handleEpisodeSelect },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-column w-100 h-100 flex dim-custom br2 relative",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex items-center ml2 mt2 absolute tracked",
                    staticStyle: { cursor: "default" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass:
                          "material-icons f3 white link pointer red adori-red",
                        class: _vm.selectionStyle + " pointer",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleAudioSelectionIconClicked.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.selectionIcon))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-black br2 tc pointer dim flex justify-center items-center custom-box",
                    class: _vm.numberOfEpisodes,
                  },
                  [
                    _vm.rssFeedsByUid[_vm.rssFeedUid].squareImageUrl
                      ? _c("img", {
                          staticClass: "br2 custom-box",
                          style: { width: "8.8rem", height: "8.8rem" },
                          attrs: {
                            src: _vm.rssFeedsByUid[_vm.rssFeedUid]
                              .squareImageUrl,
                          },
                        })
                      : _c(
                          "i",
                          {
                            staticClass:
                              "material-icons light-gray f1 br2 bg-adori-very-light-gray w2 mr3 v-mid",
                          },
                          [_vm._v("audiotrack")]
                        ),
                  ]
                ),
                this.targetEpisodeList[this.rssFeedUid]
                  ? _c("div", { staticClass: "f7" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            this.targetEpisodeList[this.rssFeedUid].count
                          ) +
                          " episodes selected\n        "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
      _vm.modalSelectEpisode
        ? _c("EpisodeSelection", {
            attrs: { rssFeedUid: _vm.rssFeedUid },
            on: { close: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }