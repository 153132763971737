




























































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import get from 'lodash.get'
import LazyLoader from '@/mixins/LazyLoader'
import TargetEpisodeList from '@/views/campaign/components/TargetEpisodeList.vue'
import moment from 'moment'

@Component({
  components: {
    TargetEpisodeList,
  },
})
export default class EpisodeSelection extends Mixins(LazyLoader) {
  @Prop(String) rssFeedUid!: string

  @Getter rssFeedsByUid!: any
  @Getter rssFeedItemsByFeedUid!: any
  @Getter targetEpisodeList!: any

  @Action updateRssFeedItems!: any

  startDateStatus = 'BEGINNING'
  endDateStatus = 'FOREVER'

  startDate = ''
  endDate = ''

  notBeforeStartDate = ''
  notAfterStartDate = ''
  notBeforeEndtDate = ''
  notAfterEndDate = ''

  payload: any = {
    rssFeedUid: this.rssFeedUid,
    limit: 10,
    order: 'recent_first',
  }

  loading = false
  selectedType: string = 'SET_DATE'

  @Watch('startDateStatus')
  startDateWatcher() {
    if (this.startDateStatus === 'BEGINNING') {
      this.payload['offset'] = 0
      delete this.payload['publishedAfter']
      this.startDate = moment(this.podcast.lastPublishedDate).toISOString()
      this.hasItemsLeft = true
      this.loadEpisodes()
    }
  }
  @Watch('endDateStatus')
  endDateWatcher() {
    if (this.endDateStatus === 'FOREVER') {
      this.payload['offset'] = 0
      delete this.payload['publishedBefore']
      this.endDate = moment().toISOString()
      this.hasItemsLeft = true
      this.loadEpisodes()
    }
  }

  get podcast() {
    return this.rssFeedsByUid[this.rssFeedUid]
  }

  get episodeList() {
    return get(this.rssFeedItemsByFeedUid[this.rssFeedUid], 'data', [])
  }

  async mounted() {
    this.startDate = moment(this.podcast.lastPublishedDate).toISOString()
    this.endDate = moment().toISOString()
    this.increment = this.payload.limit
    await this.loadEpisodes()
  }

  async loadEpisodes() {
    this.loading = true
    await this.updateRssFeedItems(this.payload)
    this.loading = false
  }

  formatter(date: any) {
    return moment(date).format('DD MMM, YYYY')
  }

  handleEndDate() {
    this.payload['offset'] = 0
    this.payload['publishedBefore'] = moment(this.endDate).toISOString()
    this.hasItemsLeft = true
    this.resetIterations()
    this.loadEpisodes()
  }

  handleStartDate() {
    this.payload['offset'] = 0
    this.payload['publishedAfter'] = moment(this.startDate).toISOString()
    this.hasItemsLeft = true
    this.resetIterations()
    this.loadEpisodes()
  }

  startDateChange(value: string) {
    this.startDateStatus = value
    if (value === 'DATE') {
      const ref: any = this.$refs.startDate
      ref.popupVisible = true
    }
  }
  endDateChange(value: string) {
    this.endDateStatus = value
    if (value === 'DATE') {
      const ref: any = this.$refs.endDate
      ref.popupVisible = true
    }
  }

  async hasItemsLeftLoad() {
    if (!this.isLoading()) {
      this.payload['offset'] = this.rssFeedItemsByFeedUid[this.rssFeedUid].data.length || 0
      await this.handleLoadMore(
        () => this.rssFeedItemsByFeedUid[this.rssFeedUid],
        this.updateRssFeedItems,
        this.payload
      )
    }
  }

  handleSelect() {
    const payload: any = {
      feedUid: this.rssFeedUid,
    }
    payload['episodesAfterTime'] = this.startDateStatus === 'BEGINNING' ? null : moment(this.startDate).toISOString()
    payload['episodesBeforeTime'] = this.endDateStatus === 'FOREVER' ? null : moment(this.endDate).toISOString()

    payload['count'] = this.rssFeedItemsByFeedUid[this.rssFeedUid].count

    this.$store.commit('selectEpisodes', payload)
    this.$emit('close')
  }

  destroyed() {}
}
