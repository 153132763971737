










































import { Component, Mixins } from 'vue-property-decorator'
import ModalSelectShows from '@/components/Modals/ModalSelectShows.vue'
import TargetList from '@/views/campaign/components/TargetList.vue'
import TargetEpisodeList from '@/views/campaign/components/TargetEpisodeList.vue'
import { Action, Getter } from 'vuex-class'
import LazyLoader from '@/mixins/LazyLoader'
import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import moment from 'moment'

@Component({
  components: {
    ModalSelectShows,
    TargetList,
    TargetEpisodeList,
  },
})
export default class Target extends Mixins(LazyLoader) {
  @Getter targetEpisodeList!: any
  @Getter rssFeedItemsByFeedUid!: any

  @Action updateRssFeedItems!: any

  loading = false

  section = 'SHOW'

  payload: any = {
    feedUid: '',
    limit: 10,
    order: 'recent_first',
  }

  rssFeedUid = ''
  showModal = false
  openSelectShowsModal() {
    this.showModal = true
  }

  closeModal() {
    this.$store.commit('unselectAllEpisodes')
    this.showModal = false
  }

  isEmpty(obj: any) {
    return isEmpty(obj)
  }

  get episodeList() {
    return get(this.rssFeedItemsByFeedUid[this.rssFeedUid], 'data', [])
  }

  async changeSection(id: string) {
    this.payload['rssFeedUid'] = id
    this.rssFeedUid = id
    this.payload['offset'] = 0
    delete this.payload['publishedAfter']
    delete this.payload['publishedBefore']
    const target = this.targetEpisodeList[id]
    if (target['episodesAfterTime']) this.payload['publishedAfter'] = moment(target['episodesAfterTime']).toISOString()
    if (target['episodesBeforeTime']) {
      this.payload['publishedBefore'] = moment(target['episodesBeforeTime']).toISOString()
    }
    this.loading = true
    this.section = 'EPISODE'
    this.hasItemsLeft = true
    this.increment = this.payload.limit
    this.resetIterations()
    await this.updateRssFeedItems(this.payload)
    this.loading = false
  }

  async hasItemsLeftLoad() {
    if (!this.isLoading()) {
      this.payload['offset'] = this.rssFeedItemsByFeedUid[this.rssFeedUid].data.length || 0
      await this.handleLoadMore(
        () => this.rssFeedItemsByFeedUid[this.rssFeedUid],
        this.updateRssFeedItems,
        this.payload
      )
    }
  }

  saveClose() {
    this.showModal = false
  }
}
