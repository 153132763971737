var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.isEmpty(_vm.targetEpisodeList)
        ? _c(
            "div",
            { staticClass: "flex flex-column items-center ml5 mt6" },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/campaign/Target.png"),
                  alt: "",
                  width: "220",
                  height: "200",
                },
              }),
              _c("BaseButtonRed", {
                staticClass: "ph4 mt3 mr1 h2 mt5",
                attrs: {
                  text: "Target Shows",
                  onClick: _vm.openSelectShowsModal,
                },
              }),
            ],
            1
          )
        : _c("div", { staticClass: "scroll ph2" }, [
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center min-w7 overflow-x-hidden ml5 mt4",
                  },
                  [
                    _c("div", {
                      staticClass: "loader loader-inline overflow-x-hidden",
                    }),
                  ]
                )
              : _c("div", [
                  _vm.section === "SHOW"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "scroll w-100 ph2 custom-height scrollbar",
                        },
                        _vm._l(_vm.targetEpisodeList, function (target, index) {
                          return _c("TargetList", {
                            key: index,
                            attrs: { target: target },
                            on: { section: _vm.changeSection },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.section === "EPISODE"
                    ? _c(
                        "div",
                        {
                          staticClass: "flex items-center mt4 pointer",
                          on: {
                            click: function ($event) {
                              _vm.section = "SHOW"
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons f3 white link pointer pointer",
                            },
                            [_vm._v("arrow_back")]
                          ),
                          _vm._v("back\n      "),
                        ]
                      )
                    : _vm._e(),
                  _vm.section === "EPISODE"
                    ? _c(
                        "div",
                        { staticClass: "mt3 custom-height scrollbar" },
                        [
                          _vm._l(_vm.episodeList, function (episode, index) {
                            return _c("TargetEpisodeList", {
                              key: index,
                              attrs: { episode: episode },
                            })
                          }),
                          _vm.hasItemsLeft
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "tc pv3 ph4 bt b--adori-gray bw1",
                                },
                                [
                                  _c("BaseButtonLoadMore", {
                                    attrs: {
                                      rootBottomMargin: "800",
                                      text: "Load more",
                                      onClick: _vm.hasItemsLeftLoad,
                                      onVisible: _vm.hasItemsLeftLoad,
                                      loading: _vm.isLoading(),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
          ]),
      _vm.showModal
        ? _c("ModalSelectShows", {
            on: { close: _vm.closeModal, saveClose: _vm.saveClose },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }