var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "absolute ml6" },
            [_c("TheTitle", { attrs: { goBack: "" } })],
            1
          ),
          _c("div", { staticClass: "limiter scrollbar mt4 mb5" }, [
            _c(
              "div",
              { staticClass: "flex justify-between items-center w-100" },
              [
                _c("TheTitle", {
                  attrs: {
                    icon: "dvr",
                    title: _vm.isCampaignFetching
                      ? "loading..."
                      : _vm.campaign.name,
                    type: "img",
                    svgIcon: _vm.campaign_icon_active,
                    large: "",
                  },
                }),
                !_vm.isCampaignFetching &&
                _vm.flightsCount !== 0 &&
                !["COMPLETE", "ARCHIVED"].includes(_vm.campaign.status)
                  ? _c("BaseButtonRed", {
                      staticClass: "ph4 mt3 mr1 h2",
                      attrs: {
                        text: "Create Flight",
                        onClick: _vm.createFlight,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "flex justify-between items-center w-100 mb3 flex-row-reverse",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-center silver" },
                    _vm._l(_vm.filterConfig, function (filter) {
                      return _c(
                        "span",
                        {
                          staticClass: "ph3 pv2 pointer ttc",
                          class: {
                            "bb b--red":
                              _vm.currentTab === _vm.filterConfig[filter],
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleTabChange(
                                _vm.filterConfig[filter]
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.filterConfig[filter].toLocaleLowerCase())
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm.isFlightFetching || _vm.isCampaignFetching
                ? _c(
                    "div",
                    _vm._l(new Array(11), function (_, index) {
                      return _c("div", {
                        key: index,
                        staticClass:
                          "w-100 bg-adori-light-gray bb bw1 b--adori-gray flex justify-center items-center skeleton cus-size mb2",
                      })
                    }),
                    0
                  )
                : _c("div", [
                    _vm.flightsData.length
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-wrap justify-between ph2 pv3 link light-gray bg-adori-light-gray bb b--adori-gray br2 relative",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "w-20 flex justify-center" },
                                  [_vm._v("Name")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "w-10 flex justify-center" },
                                  [_vm._v("Dates")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "w-10 flex justify-center" },
                                  [_vm._v("Flight Type")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "w-10 flex justify-center" },
                                  [_vm._v("Progress")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "w-10 flex justify-center" },
                                  [_vm._v("Position")]
                                ),
                                _vm.campaign.kind !== _vm.promoType.PROGRAMMATIC
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "w-10 flex justify-center",
                                      },
                                      [_vm._v("Ad Preview")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "w-10 flex justify-center" },
                                  [_vm._v("Status")]
                                ),
                                _c("div", {
                                  staticClass: "w-5 flex justify-center",
                                }),
                              ]
                            ),
                            _vm._l(_vm.flightsData, function (flight, index) {
                              return _c("TheFlightList", {
                                key: index,
                                attrs: {
                                  flight: flight,
                                  campaign: _vm.campaign,
                                },
                                on: { updated: _vm.update },
                              })
                            }),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-end pv3 ph4 bt bg-adori-light-gray b--adori-gray bw1",
                              },
                              [
                                _c("BaseButtonRed", {
                                  staticClass: "mr3 youtube-link",
                                  attrs: {
                                    text: "Prev",
                                    onClick: _vm.prev,
                                    disabled: _vm.pageNo === 1,
                                  },
                                }),
                                _c("input", {
                                  staticClass:
                                    "bg-adori-light-gray adori-gray br2 w-38",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.pageNo },
                                  on: {
                                    input: function ($event) {
                                      _vm.OnPageNumberChanged(
                                        parseInt($event.target.value)
                                      )
                                    },
                                  },
                                }),
                                _c("span", { staticClass: "pa2 silver" }, [
                                  _vm._v("/ " + _vm._s(_vm.pageCount)),
                                ]),
                                _c("BaseButtonRed", {
                                  staticClass: "ml2 youtube-link",
                                  attrs: {
                                    text: "Next",
                                    onClick: _vm.next,
                                    disabled: _vm.pageNo === _vm.pageCount,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.flightsData.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "bg-adori-light-gray br2 ba b--adori-gray light-gray vh-75 flex justify-center items-center column",
                          },
                          [
                            _c("div", [
                              _c("p", { staticClass: "f3 mb5" }, [
                                _vm._v("Create Flight"),
                              ]),
                            ]),
                            _c("img", {
                              attrs: {
                                src: _vm.placHolderSrc,
                                alt: "",
                                width: "200",
                                height: "200",
                              },
                            }),
                            _c("div", { staticClass: "shadow" }),
                            _c("div", { staticClass: "mb3" }, [
                              _vm._v("Oops.. no Flight yet! create one"),
                            ]),
                            !["COMPLETE", "ARCHIVED"].includes(
                              _vm.campaign.status
                            )
                              ? _c("BaseButtonRed", {
                                  staticClass: "ph4 mt3 mr1 h2",
                                  attrs: {
                                    text: "Create Flight",
                                    onClick: _vm.createFlight,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
            ]),
          ]),
          _vm.flightModalStatus
            ? _c("ModalCreateFlight", {
                attrs: { campaign: _vm.campaign },
                on: { close: _vm.closeModal, updated: _vm.update },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }