


















































































































import moment from 'moment'
import isEmpty from 'lodash.isempty'
import { Component, Prop, Vue } from 'vue-property-decorator'

// modals
import CountryModal from '@/components/Tags/Modals/CountryModal.vue'

// utils
import countries from '@/components/Tags/Modals/countryList'

@Component({
  components: { CountryModal },
})
export default class FlightDetails extends Vue {
  @Prop() flight!: any
  @Prop() campaign!: any

  flightName = ''

  placement = 'PREROLL'
  deliveryPlacement = ['PREROLL', 'MIDROLL', 'POSTROLL']

  position = 0
  positions = [
    { text: 'Any', value: 0 },
    { text: '1', value: 1 },
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
  ]

  flightStartDate = ''
  flightEndDate = ''

  isUploadingTag = false

  notBeforeStartDate: any = moment().utc().toISOString()

  notAfterStartDate: any = null
  notBeforeEndDate: any = moment().utc().toISOString()
  notAfterEndtDate: any = null

  cap = 100000

  showGeoModal = false

  dateDiff = false

  validationObj = {
    flightName: false,
    date: false,
  }

  geoTargetData: { country: string; name: string }[] = []

  created() {
    if (!isEmpty(this.flight)) {
      this.flightName = this.flight.name
      // this.flightStatus = this.flight.status
      this.flightStartDate =
        moment(this.campaign.startTime).diff(this.flight.startTime) >= 0
          ? this.campaign.startTime
          : this.flight.startTime
      // this.flightStartDate = this.flight.startTime

      this.flightEndDate =
        moment(this.campaign.endTime).diff(this.flight.endTime) <= 0 ? this.campaign.endTime : this.flight.endTime
      // this.flightEndDate = this.flight.endTime
      this.placement = this.flight.position
      this.position = this.flight.positionNumber
      this.cap = this.flight.maxServes
      const targets = this.flight.geoTargets || []
      const geo: { country: string; name: string }[] = []

      for (let item of targets) {
        let check = countries.filter((val) => {
          if (val.code === item.country) {
            geo.push({
              country: val.code,
              name: val.name,
            })
          }
        })
      }
      this.geoTargetData = geo
    }

    if (this.campaign.endTime !== null) {
      this.notAfterStartDate = this.campaign.endTime
      this.notAfterEndtDate = this.campaign.endTime
    }
    let date = moment().startOf('day').utc()
    if (date.diff(moment(this.campaign.startTime), 'days') < 0 && this.campaign.endTime !== null) {
      this.dateDiff = true
      this.notBeforeStartDate = this.campaign.startTime
      this.notBeforeEndDate = this.campaign.startTime
    }
  }

  addLocation() {
    this.showGeoModal = !this.showGeoModal
  }

  removeLocation(e: any) {
    let curTarget = e.currentTarget
    if (curTarget.dataset) {
      const country = curTarget.dataset.country
      let geoTargets: any = []
      geoTargets = this.geoTargetData
      const geo = geoTargets.filter((target: { country: string }) => target.country !== country)
      this.geoTargetData = geo
    }
  }
  saveLocation(selectedData: { name: string; code: string }) {
    let geoTargets: { country: string; name: string }[] = []
    if (this.geoTargetData && this.geoTargetData.length > 0) {
      geoTargets = this.geoTargetData
    }
    const isInArray = geoTargets.find((el: any) => el.code === selectedData.code) !== undefined
    if (!isInArray) {
      geoTargets.push({
        country: selectedData.code,
        name: selectedData.name,
      })
    }
    this.geoTargetData = geoTargets
    this.addLocation()
  }

  flightNameValidation() {
    if (this.flightName === '') this.validationObj.flightName = true
    else this.validationObj.flightName = false
  }

  validateDate() {
    this.notAfterStartDate = this.flightEndDate

    if (['', null].includes(this.flightEndDate)) {
      this.notAfterStartDate = this.campaign.endTime
    }

    if (!['', null].includes(this.flightStartDate)) {
      this.notBeforeEndDate = this.flightStartDate
    } else {
      this.notBeforeEndDate = this.dateDiff ? this.campaign.startTime : moment().utc().toISOString()
    }

    if ([null, ''].includes(this.flightStartDate) || [null, ''].includes(this.flightEndDate)) {
      this.validationObj.date = true
    } else this.validationObj.date = false
  }

  validation() {
    this.flightNameValidation()
    this.validateDate()
    if (this.validationObj.flightName === true || this.validationObj.date === true) return true
    else return false
  }

  handleNext() {
    if (this.validation()) return
    const payload: any = {
      name: this.flightName,
      maxServes: this.cap,
      position: this.placement,
      positionNumber: this.position,
      geoTargets: this.geoTargetData,
    }
    if (this.flightStartDate === null || this.flightEndDate === null) {
      payload['startTime'] = moment().startOf('day').utc().toISOString()
      payload['endTime'] = null
    } else {
      payload['startTime'] = moment(this.flightStartDate).startOf('day').utc().toISOString()
      payload['endTime'] = moment(this.flightEndDate).endOf('day').utc().toISOString()
    }
    this.$emit('next', payload)
  }
}
