























































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import TargetList from '@/views/campaign/components/TargetList.vue'
import LazyLoader from '@/mixins/LazyLoader'
import get from 'lodash.get'
import TargetEpisodeList from '@/views/campaign/components/TargetEpisodeList.vue'
import moment from 'moment'
import { targetPayload, showsResponse } from '@/Interfaces/StoreInterFaces/campaignInterfaces/Campaign'

@Component({
  components: {
    TargetList,
    TargetEpisodeList,
  },
})
export default class ModalTargettedShows extends Mixins(LazyLoader) {
  @Prop() campaign!: any
  @Prop() flight!: any

  @Getter targetEpisodeList!: any
  @Getter networkId!: string
  @Action getShows!: any

  @Getter rssFeedItemsByFeedUid!: any

  @Action updateRssFeedItems!: any

  payload: any = {
    feedUid: '',
    limit: 10,
    order: 'recent_first',
  }
  rssFeedUid = ''
  section = 'SHOW'

  loading = false
  async mounted() {
    this.loading = true
    const payload = {
      params: {
        limit: 100,
        offset: 0,
      },
      networkId: this.networkId,
      campaignId: this.campaign.id,
      flightId: this.flight.id,
    }
    const shows: any = await this.getShows(payload)
    const episodeList = shows.data.reduce((acc: { [id: string]: targetPayload }, obj: showsResponse) => {
      acc[obj.feed.uid] = {
        feedUid: obj.feed.uid,
        episodesAfterTime: obj.episodesAfterTime,
        episodesBeforeTime: obj.episodesBeforeTime,
        count: obj.targetedEpisodes,
      }

      return acc
    }, {})
    this.$store.commit('setTargetEpisodeList', episodeList)
    this.loading = false
  }

  get episodeList() {
    return get(this.rssFeedItemsByFeedUid[this.rssFeedUid], 'data', [])
  }

  async changeSection(id: string) {
    this.loading = true
    this.payload['offset'] = 0
    this.payload['rssFeedUid'] = id
    this.rssFeedUid = id
    delete this.payload['publishedAfter']
    delete this.payload['publishedBefore']
    const target = this.targetEpisodeList[id]

    if (target['episodesAfterTime']) this.payload['publishedAfter'] = moment(target['episodesAfterTime']).toISOString()
    if (target['episodesBeforeTime']) {
      this.payload['publishedBefore'] = moment(target['episodesBeforeTime']).toISOString()
    }
    this.section = 'EPISODE'
    this.hasItemsLeft = true
    this.increment = this.payload.limit
    this.resetIterations()
    await this.updateRssFeedItems(this.payload)
    this.loading = false
  }

  async hasItemsLeftLoad() {
    if (!this.isLoading()) {
      this.payload['offset'] = this.rssFeedItemsByFeedUid[this.rssFeedUid].data.length || 0
      await this.handleLoadMore(
        () => this.rssFeedItemsByFeedUid[this.rssFeedUid],
        this.updateRssFeedItems,
        this.payload
      )
    }
  }

  destroyed() {
    this.$store.commit('unselectAllEpisodes')
  }
}
