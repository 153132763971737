




















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import FlightDetails from '@/views/campaign/components/FlightDetails.vue'
import Target from '@/views/campaign/components/Target.vue'
import AudioAdCollection from '@/components/Common/AudioAds/AudioAdCollection.vue'
import ModalSelectShows from '@/components/Modals/ModalSelectShows.vue'
import { Action, Getter } from 'vuex-class'
import isEmpty from 'lodash.isempty'
import { TagProvider } from '@/components/Tags/tags'
import { promoType } from '@/components/Campaigns/configs'
import { targetPayload, showsResponse } from '@/Interfaces/StoreInterFaces/campaignInterfaces/Campaign'

@Component({
  components: {
    FlightDetails,
    Target,
    AudioAdCollection,
    ModalSelectShows,
  },
})
export default class ModalSponsor extends Vue {
  @Prop() campaign!: any
  @Prop() flight!: any
  @Prop(Boolean) edit!: boolean

  @Getter targetEpisodeList!: any
  @Getter selectedAudioUids!: any
  @Getter networkId!: string

  @Action changesAudioAdUid!: any
  @Action getAudioAds!: any
  @Action createFlight!: any
  @Action updateFlight!: any
  @Action updateShows!: any
  @Action getShows!: any

  validationStatus: boolean = false

  tabParam = 'Details'
  selectedAudioAdId = ''
  showModal = false
  payload: any = {}

  isUploadingTag = false

  get flightButton() {
    return this.edit ? 'Update Flight' : 'Create Flight'
  }

  isEmpty(obj: any) {
    return isEmpty(obj)
  }

  async created() {
    this.tabParam = 'Details'
    if (!isEmpty(this.flight)) {
      this.$store.dispatch('toggleSelectAudio', this.flight.provider.audioTrackUid)
      const payload = {
        params: {
          limit: 100,
          offset: 0,
        },
        networkId: this.networkId,
        campaignId: this.campaign.id,
        flightId: this.flight.id,
      }
      const shows: any = await this.getShows(payload)
      const episodeList = shows.data.reduce((acc: { [id: string]: targetPayload }, obj: showsResponse) => {
        acc[obj.feed.uid] = {
          feedUid: obj.feed.uid,
          episodesAfterTime: obj.episodesAfterTime,
          episodesBeforeTime: obj.episodesBeforeTime,
          count: obj.targetedEpisodes,
        }

        return acc
      }, {})

      this.$store.commit('setTargetEpisodeList', episodeList)
    }
  }

  nextToAudioSelection(data: any) {
    this.payload['data'] = {}
    this.payload.data = data

    if (this.edit && this.campaign.kind === promoType.PROGRAMMATIC) this.tabParam = 'Target'
    else if (!this.edit && this.campaign.kind === promoType.PROGRAMMATIC) {
      this.tabParam = 'Target'
      this.payload.data['provider'] = {
        kind: TagProvider.PROGRAMMATIC,
        maxDurationSeconds: 30,
      }
    } else if (this.edit) this.tabParam = 'Target'
    else this.tabParam = 'Audio'
  }
  nextToShows(provider: any) {
    this.tabParam = 'Target'
    if (!this.edit) this.payload.data['provider'] = provider
  }

  async createAdoriFlight() {
    try {
      this.isUploadingTag = true
      this.payload['networkId'] = this.networkId
      this.payload['campaignId'] = this.campaign.id
      let flightId!: any

      if (this.edit) {
        this.payload['flightId'] = this.flight.id
        flightId = await this.updateFlight(this.payload)
      } else flightId = await this.createFlight(this.payload)

      const target = Object.values(this.targetEpisodeList)
        .map((obj: any) => JSON.parse(JSON.stringify(obj)))
        .map((obj: targetPayload) => {
          return {
            feedUid: obj.feedUid,
            episodesAfterTime: obj.episodesAfterTime,
            episodesBeforeTime: obj.episodesBeforeTime,
          }
        })

      this.payload = {
        data: {
          shows: target,
        },
        networkId: this.networkId,
        campaignId: this.campaign.id,
        flightId: flightId,
      }
      await this.updateShows(this.payload)
      this.isUploadingTag = false
      this.$emit('close')
      this.$emit('updated')
    } catch (error) {
      this.isUploadingTag = false
      this.$emit('close')
    }
  }

  destroyed() {
    this.$store.commit('unselectAllEpisodes')
  }

  closeModal() {
    this.showModal = false
  }
  saveClose() {
    this.showModal = false
  }
}
